.link {
  color: black;
  position: relative;
  margin-top: 40px;
  text-decoration: none;
}

.link h2 {
  font-size: 2.5rem;
  line-height: 20px;
}

.link ::after {
  content: "";
  position: absolute;
  left: 20px;
  bottom: -10px;
  width: calc(100% - 10px);
  height: calc(50%);
  z-index: -1;
  background-color: #4e9fd69c;
  transition: 0.6s cubic-bezier(0.25, 0.1, 0, 2.05);
}

.link :hover::after {
  left: 0;
  bottom: -2px;
  width: calc(100% + 10px);
  height: calc(100% + 5px);
}

@media only screen and (max-width: 750px) {
  .link h2 {
    font-size: 1.75rem;
    line-height: 20px;
  }
}

@media only screen and (max-width: 530px) {
  .link h2 {
    font-size: 1.5rem;
    line-height: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .link h2 {
    font-size: 1.25rem;
    line-height: 20px;
  }
}
