.study-cases {
  color: black;
  margin-top: 30px;
}

.study-cases h2 {
  font-size: 3.5rem;
  font-weight: 700;
}

.study-cases ::after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: -10px;
  width: calc(100% - 10px);
  height: calc(40% - 10px);
  z-index: -1;
  background-color: transparent;
  transition: 0.6s cubic-bezier(0.25, 0, 0, 1.05);
}

.study-cases :hover::after {
  background-color: #4e9fd69c;
  left: 0;
  bottom: -2px;
  width: calc(100% + 10px);
  height: calc(100%);
}

.about-link a {
  color: black;
  text-decoration: none;
  font-weight: 900;
}

.about-link {
  margin-top: 30px;
  position: relative;
  z-index: 2;
  width: max-content;
}

.about-link ::after {
  content: "";
  position: absolute;
  left: 5px;
  bottom: -7px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  z-index: -1;
  background-color: #4e9fd69c;
  transition: 0.6s cubic-bezier(0.25, 0.1, 0, 2.05);
}

.about-link :hover::after {
  left: 0;
  bottom: -2px;
  width: calc(100% + 10px);
  height: calc(100% + 5px);
}

.animation-first-order {
  animation: slideInFromLeft 1.5s ease-out;
}

.animation-second-order {
  opacity: 0;
  animation: slideInFromLeft 1.5s ease-out forwards;
  animation-delay: 1.5s;
}

#astranec {
  opacity: 0;
  animation: slideInFromRight 1.5s ease-out forwards;
  animation-delay: 0.5s;
}

#astranecId:hover .astranecBackground {
  display: block;
}

#wlessbuds {
  opacity: 0;
  animation: slideInFromRight 1.5s ease-out forwards;
  animation-delay: 1s;
}

#hdnetmovies {
  opacity: 0;
  animation: slideInFromRight 1.5s ease-out forwards;
  animation-delay: 1.5s;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  20% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.hideBackground {
  opacity: 0;
}

.showBackground {
  opacity: 1;
  color: white;
}

.backgroundHero {
  transition: 1s ease-out;
}

.sliderBackgroundShow {
  opacity: 0.3;
}
