.project-image-container {
  position: relative;
  height: 360px;
  width: 80%;
  margin-top: 100px;
  background: rgba(0, 0, 0, 0);
  transform: rotate(-25deg) skew(25deg) scale(1);
}

.project-image-container img {
  position: absolute;
  width: 100%;
  transition: 1s ease-out;
}

.live-demo-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.scrolled .project-image-container img:nth-child(4) {
  transform: translate(160px, -160px);
  opacity: 1;
}
.scrolled .project-image-container img:nth-child(3) {
  transform: translate(120px, -120px);
  opacity: 0.8;
}
.scrolled .project-image-container img:nth-child(2) {
  transform: translate(80px, -80px);
  opacity: 0.6;
}
.scrolled .project-image-container img:nth-child(1) {
  transform: translate(40px, -40px);
  opacity: 0.4;
}

.project-image-container:hover img {
  animation: hoverImageAnimation 0.7s ease-out forwards;
  animation-delay: 0.5s;
}

.project-image-container:hover {
  animation: hoverContainerAnimation 0.7s ease-out forwards;
  animation-delay: 1.2s;
}

.project-image-container:hover .live-demo-button {
  animation: hoverButtonAnimation 0.5s ease-out forwards;
  animation-delay: 1.9s;
}

@keyframes hoverImageAnimation {
  0% {
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes hoverContainerAnimation {
  0% {
  }
  100% {
    transform: rotate(0) skew(0) scale(1.2);
    margin-top: 0;
    margin-bottom: 50px;
  }
}

@keyframes hoverButtonAnimation {
  0% {
  }
  100% {
    opacity: 1;
  }
}
